<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <ion-text class="regular cWhite ion-text-center">
            <h1 class="ion-no-margin menuTitleFont">Mes Commandes</h1>
          </ion-text>
        </div>
        <div class="maxWidth">
          <div>
            <div>
              <div v-if="missionsDisplayed.length > 0">
                <div v-for="ms in missionsDisplayed" :key="ms.id">
                  <PharmacistMission
                      :day="ms.day"
                      :begin-time="ms.beginTime"
                      :end-time="ms.endTime"
                      :status="ms.status"
                      :pause-hours="ms.pauseHours"
                      :increase="ms.increase"
                      :grade="ms.grade"
                      :user="ms.user"
                      :id="ms.id"
                  >
                  </PharmacistMission>
                </div>
              </div>
              <div v-if="!hasLoad">
                  <h3 class="ion-text-center ion-margin-top">
                    Chargement en cours ...
                  </h3>
              </div>
              <div v-if="hasLoad && missionsDisplayed.length == 0">
                <h3 class="ion-text-center ion-margin-top">
                    Aucune commande pour le moment
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDesktop" class="footerBackground">
        <img
            class="ion-margin-bottom logoFooter"
            src="../../public/assets/icon/logo-alloo-pharma.svg"
        />
      </div>
      <div v-else class="footerNotMobile">
        <img
            class="ion-margin-bottom logoFooter"
            src="../../public/assets/icon/alloopharma-logo.png"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import store from "@/store";
import { IonPage, IonText, IonContent } from "@ionic/vue";
import PharmacistMission from "@/components/PharmacistMission";
import {getDashboard} from "@/services/api";

export default {
  name: "PharmacistMissions",
  components: {
    PharmacistMission,
    IonText,
    IonContent,
    IonPage,
  },
  data() {
    return {
      hasLoad: false,
    };
  },
  computed: {
    isDesktop() {
      return store.getters.isDesktop;
    },
    missionsDisplayed() {
      return store.getters.missionShifts || [];
    },

  },
  ionViewWillEnter() {
    getDashboard().then(() => this.hasLoad = true)
  },
};
</script>
<style scoped>
h1 {
  padding: 50px;
  margin-top: -20px;
}
</style>
