<template>
  <div class="profile-picture-container" style="border: 3px solid rgba(53, 148, 225, 0.68);">
    <img :src="user.profilePicture.url" v-if="user.profilePicture.url" alt="photo de profil de l'utilisateur" class="profile-picture"/>
    <ion-icon color="light" :icon="personCircleSharp" class="profile-picture" v-else/>
  </div>
</template>

<script>
import {
  IonIcon,
} from "@ionic/vue";
import {
  personCircleSharp
} from "ionicons/icons";
export default {
  name: 'ProfilePicture',
  components: {
    IonIcon,
  },
  props: {
    user: {type: Object, required: true,},
  },
  setup() {
    return {
      personCircleSharp
    };
  },
}
</script>

<style scoped>
.profile-picture-container {
  border: 3px solid #ffce8f;
  background: gray;
  width: 18vw !important;
  height: 18vw !important;
  margin-left: 1rem;
  border-radius: 100%;
  cursor: pointer;
  max-width: 100px;
  max-height: 100px;
  min-width: 70px;
  min-height: 70px;
}

.profile-picture {
  width: 100% !important;
  height: 100% !important;
  border-radius: 100%;
  object-fit: cover;
}

.profile-picture__icon {
  width: 100%;
}
</style>
