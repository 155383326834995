<template>
  <div id="container">
    <ion-card class="fullCard">
      <ion-card-header>
        <ion-card-title>
          <div class="ion-margin-bottom black">
            <div style="display: flex; justify-content: center; align-items: baseline; padding: 0 10px 0 10px;">
              <h2 class="dateConfirmed">Mission du {{ day }} <span :class="userLight" /></h2>
            </div>
            <div class="content-wrapper">
              <div style="width: 45%" class="hours-container">
                <h3 class="ion-text-left">🕒 {{ beginTime }} - {{ endTime }}</h3>
                <h3 class="ion-text-left">☕ {{ pauseHours }}</h3>
                <h3 class="ion-text-left" ><span>{{ hasUser ? '✔️' : '🔎'}} {{ this.localStatus }} </span></h3>
              </div>
              <div style="width: 45%">
                <div v-if="hasUser" style="display:flex; flex-direction: column">
                  <!-- <ProfilePicture :user="user" /> -->
                  <h3 style="margin-top: 20px;margin-bottom: 5px;margin-left: 0;">{{ user.firstName }}</h3>
                  <h6 style="margin-bottom: 0px;margin-top: 10px;"> {{ user.grade }}</h6>
                  <h6 style="margin-bottom: 0px;margin-top: 10px;">Heures travaillées : {{ user.recommendations  }}</h6>
                  <h6 style="margin-top: 5px;">Nombre de likes : {{ user.workedHours }}</h6>
                  <ion-button style="margin-top: 10px;" v-if="this.user.cvLink" @click="this.openCV">Consulter le CV</ion-button>
                </div>
              </div>
            </div>
          </div>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content class="mainCard" style="margin-left: 25px;">
        <ion-button v-if="this.localStatus == 'Intérimaire trouvé'" class="ion-item-center ion-margin-left" v-on:click="validateMission" style="margin-left: 5px;margin-bottom: 15px;">
          Valider l'intérimaire
        </ion-button>
        <ion-button v-if="this.localStatus == 'Intérimaire trouvé'" class="ion-item-center" v-on:click="declineMission" style="margin-left: 5px;margin-bottom: 15px;">
          Refuser l'intérimaire
        </ion-button>
        <ion-button v-if="this.localStatus != 'Intérimaire trouvé'" class="ion-item-center " v-on:click="showMission" style="margin-left: 5px;margin-bottom: 15px;">
          Voir Plus
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</template>
<script>
import store from "@/store";
import { closeOutline } from "ionicons/icons";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from "@ionic/vue";
import router from "@/router";
import { getDashboard, validateShift, declineShift } from "@/services/api";
import ProfilePicture from "@/components/ProfilePicture";

export default {
  name: "PharmacistMission",
  components: {
    // ProfilePicture,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
  computed: {
    userLight() {
      if (this.hasUser) {
        return "bullet bullet--green";
      }
      return 'bullet';
    },
    statusUnderline() {
      if (this.hasUser) {
        return "underline--green";
      }
      return 'underline--orange';
    },
    gender() {
      if (!this.hasUser) {
        return null;
      }
      if (this.user.gender === 'homme') {
        return '♂';
      } else if (this.user.gender === 'femme') {
        return '♀';
      }
      return '⚥';
    }
  },
  data(){
    return {
      localStatus : this.status
    }
  },
  setup(props) {
    const hasUser = Object.keys(props.user).length > 0;
    return {
      closeOutline,
      hasUser,
    };
  },
  props: {
    id: { type: Number, required: true },
    day: { type: String, required: true },
    beginTime: { type: String, required: true },
    endTime: { type: String, required: true },
    status: { type: String, required: true},
    pauseHours: { type: String, required: true },
    increase: { type: Number, required: true },
    grade: { type: String, required: true },
    workedHours: { type: Number, required: false },
    isFlexible: { type: Boolean, required: false },
    ownerStatus: { type: String, required: false },
    user: { type: Object, required: false, default: () => ({}) },
  },

  ionViewDidEnter() {
    getDashboard();
  },
  
  methods: {
    showMission() {
      store.dispatch('setCurrentMission', { id: this.id });
      router.push("/ma-mission")
    },
    openCV() {
      const cvLink = this.user.cvLink; // Remplacez par le lien de votre CV
      window.open(cvLink, '_blank');
    },
    async validateMission(){
      const mission = await validateShift(this.id)
      this.localStatus = "Validé"
    },
    async declineMission(){
      const mission = await declineShift(this.id)
      this.localStatus = "Refusé"
    }
  },
};
</script>
<style scoped>
.content-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.hours-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media screen and (max-device-width:640px), screen and (max-width:640px) {
  .content-wrapper {
    flex-direction: column;
  }
  .content-wrapper > * {
    width: 100% !important;
  }
  .hours-container {
    flex-direction: row;
  }
}
.bullet{
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  background-color: #ffc107;
  display: inline-block;
}

.bullet--green{
  background-color: #4caf50;
}

.underline--green {
  text-decoration: underline #4caf50;
}

.underline--orange {
  text-decoration: underline #ffc107;
}

#container {
  --ion-grid-columns: 24;
}

.mainCard {
  padding: 8px 8px 0;
}

h1 {
  color: rgb(109, 109, 109);
  font-size: 18px;
  font-family: "SFProTextBold";
  margin-top: 0px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-weight: medium;
  font-size: 20px;
  margin: 5px;
}

h5 {
  margin: 5px;
  color: var(--ion-color-secondary);
}

.headerCard {
  background-color: #d56498;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0 !important;
  color: rgb(255, 247, 247);
}

ion-card-header {
  padding: 0px;
  padding-inline: 0px;
}

.black {
  color: black;
}

.fullCard {
  padding-bottom: 0px;
  border-radius: 35px;
  margin-bottom: 30px;
}
.description {
  font-size: 14px;
  color: var(--ion-color-secondary);
}
.address {
  width: 97%;
}
ion-card-content {
  padding: 0px !important;
}

</style>
